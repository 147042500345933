import React from "react"
import LeftLayout from "./LeftLayout"
import RightLayout from "./RightLayout"

const Hero = () => {
  return (
    <section
      id="hero"
      className="flex h-screen bg-whisper flex-col md:flex-row"
    >
      <LeftLayout>
        <div className="ml-10 text-8xl font-bold text-whisper mb-5 md:mr-5 xl:text-10xl 2xl:text-12xl">
          <p
            data-sal="slide-right"
            data-sal-duration="500"
            data-sal-easing="easeInOutSine"
          >
            HELLO
          </p>
        </div>
      </LeftLayout>
      <RightLayout>
        <div
          data-sal="slide-left"
          data-sal-duration="500"
          data-sal-easing="easeInOutSine"
          className="ml-10 mt-5 md:ml-5"
        >
          <p className="text-seagreen text-6xl xl:text-8xl 2xl:text-10xl">
            I'm
          </p>
          <p className="text-seagreen font-semibold text-6xl xl:text-8xl 2xl:text-10xl">
            Samuel Leong
          </p>
          <p className="mt-5 text-tomato text-4xl xl:text-5xl 2xl:text-6xl">
            Computer Science <span className="text-tomato">Undergraduate</span>
          </p>
        </div>
      </RightLayout>
    </section>
  )
}

export default Hero
