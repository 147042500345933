import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { MARKS, INLINES } from "@contentful/rich-text-types"
import React from "react"
import LeftLayout from "./LeftLayout"
import RightLayout from "./RightLayout"

import sanitizeHtml from "sanitize-html"
import { graphql, useStaticQuery } from "gatsby"
import { SkipNavContent } from "@reach/skip-nav"

const query = graphql`
  {
    allContentfulInfo {
      nodes {
        id
        title
        subtitle {
          raw
        }
        description {
          childMarkdownRemark {
            html
          }
        }
        avatar {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, width: 256)
        }
      }
    }
  }
`

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <b className="font-bold text-seagreen">{text}</b>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const { uri } = node.data
      return (
        <a href={uri} rel="noreferrer" target="_blank">
          {children}
        </a>
      )
    },
  },
}

const Info = () => {
  const {
    allContentfulInfo: { nodes },
  } = useStaticQuery(query)
  const info = nodes[0]
  const image = getImage(info.avatar)
  const text = info.description.childMarkdownRemark.html
  const clean = sanitizeHtml(text, {
    allowedTags: false,
    allowedAttributes: false,
  })
  return (
    <>
      <SkipNavContent />
      <section
        className="h-[800px] flex flex-col md:flex-row md:items-center md:h-[600px]"
        id="info"
      >
        <LeftLayout height="h-3/5">
          <div
            data-sal="fade"
            data-sal-duration="300"
            data-sal-easing="easeInQuint"
            className="m-auto md:mx-20"
          >
            <GatsbyImage
              image={image}
              alt="avatar"
              className="rounded-full border-seagreen border-2"
            />
          </div>
        </LeftLayout>
        <RightLayout height="h-3/5">
          <div
            data-sal="fade"
            data-sal-delay="300"
            data-sal-duration="700"
            data-sal-easing="easeInQuint"
            className="flex flex-col max-w-lg mx-5 my-10 md:mx-20 md:my-auto xl:max-w-2xl"
          >
            <h4 className="font-bold text-verydarkgrey text-3xl lg:text-4xl">
              {info.title}
            </h4>
            <h5 className="text-dimgray text-2xl mb-3 lg:text-3xl">
              {renderRichText(info.subtitle, options)}
            </h5>
            <div
              className="lg:text-xl"
              dangerouslySetInnerHTML={{ __html: clean }}
            />
          </div>
        </RightLayout>
      </section>
    </>
  )
}

export default Info
