import React from "react"
import Hero from "./Hero"
import Info from "./Info"
import { ProjectMD } from "./ProjectMD"
import { ProjectSM } from "./ProjectSM"

const Landing = () => {
  return (
    <>
      <Hero />
      <Info />
      <div id="projects">
        <ProjectSM />
        <ProjectMD />
      </div>
    </>
  )
}

export default Landing
