import React from "react"
import RightLayout from "./RightLayout"

import { graphql, useStaticQuery } from "gatsby"
import { ButtonSlug } from "../Button"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { MARKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const query = graphql`
  {
    allContentfulProject(sort: { fields: contentful_id }) {
      nodes {
        id
        visibility
        slug {
          title
        }
        title
        subtitle
        roles
        description {
          raw
        }
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
  }
`

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <b className="font-bold text-ferra">{text}</b>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const { uri } = node.data
      return (
        <a href={uri} rel="noopener noreferrer" target="_blank">
          {children}
        </a>
      )
    },
  },
}

export const ProjectSM = () => {
  const {
    allContentfulProject: { nodes: projects },
  } = useStaticQuery(query)
  if (projects.visibility) return null
  return (
    <>
      <section
        className="flex flex-col md:flex-row md:items-center md:hidden"
        id="projects"
      >
        <div className="flex flex-row text-center m-auto text-tomato p-5">
          <h1 className="text-6xl">Recent Projects</h1>
        </div>
        {projects.map(project => {
          const image = getImage(project.image)
          const slug = project.slug.title
          return (
            <section key={project.id}>
              <RightLayout>
                <div
                  data-sal="slide-up"
                  data-sal-delay="100"
                  data-sal-duration="700"
                  data-sal-easing="easeInQuint"
                  className="flex flex-col m-auto mb-10"
                >
                  <div className="flex flex-col items-center max-w-lg mx-5 my-10 md:mx-20 md:my-auto xl:max-w-2xl ">
                    <div>
                      <h3 className="font-bold text-verydarkgrey text-3xl lg:text-4xl">
                        {project.title}
                      </h3>
                      <h4 className="text-seagreen text-xl mb-3 lg:text-2xl">
                        {project.subtitle}
                      </h4>
                      <div className="lg:text-xl">
                        {renderRichText(project.description, options)}
                      </div>
                    </div>
                    <GatsbyImage
                      image={image}
                      alt={project.title}
                      className="m-auto my-10 drop-shadow-3xl"
                    />
                    {project.roles !== "-" && (
                      <h4 className="text-seagreen text-xl mb-10 lg:text-2xl border-y-4 border-oxley border-opacity-50 py-4 ">
                        Roles: {project.roles}
                      </h4>
                    )}
                    <ButtonSlug to={slug}>View More</ButtonSlug>
                  </div>
                </div>
              </RightLayout>
            </section>
          )
        })}
      </section>
    </>
  )
}
