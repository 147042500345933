import React from "react"
import LeftLayout from "./LeftLayout"
import RightLayout from "./RightLayout"

import { graphql, useStaticQuery } from "gatsby"
import { ButtonSlug } from "../Button"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { MARKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const query = graphql`
  {
    allContentfulProject(sort: { fields: contentful_id }) {
      nodes {
        id
        slug {
          title
        }
        visibility
        title
        subtitle
        roles
        description {
          raw
        }
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
  }
`

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <b className="font-bold text-darkgray">{text}</b>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const { uri } = node.data
      return (
        <a href={uri} rel="noopener noreferrer" target="_blank">
          {children}
        </a>
      )
    },
  },
}

export const ProjectMD = () => {
  const {
    allContentfulProject: { nodes: projects },
  } = useStaticQuery(query)
  if (projects.visibility) return null
  return (
    <section className="hidden md:flex md:flex-col">
      <section
        className="flex-col flex md:flex-row md:h-[200px] md:items-center "
        id="projects"
      >
        <LeftLayout>
          <div className="flex mx-10 text-whisper">
            <h1 className="md:text-7xl lg:text-8xl">Recent</h1>
          </div>
        </LeftLayout>
        <RightLayout>
          <div className="flex mx-10 ">
            <h1 className="md:text-7xl lg:text-8xl text-tomato">Projects</h1>
          </div>
        </RightLayout>
      </section>
      {projects.map(project => {
        const image = getImage(project.image)
        const slug = project.slug.title
        return (
          <section key={project.id} className="flex items-center h-[800px]">
            <LeftLayout>
              <div
                data-sal="slide-up"
                data-sal-delay="100"
                data-sal-duration="700"
                className="flex flex-col mx-20 my-10 m-auto max-w-2xl "
              >
                <div className="mb-10">
                  <h3 className="font-bold text-whisper text-3xl lg:text-4xl">
                    {project.title}
                  </h3>
                  <h4 className="text-oxley text-xl mb-3 lg:text-2xl">
                    {project.subtitle}
                  </h4>
                  <div className="text-whisper lg:text-xl">
                    {renderRichText(project.description, options)}
                  </div>
                </div>
                {project.roles !== "-" && (
                  <p className="w-fit text-whisper text-xl mb-10 lg:text-2xl border-y-4 border-whisper border-opacity-50 py-4 ">
                    Roles: {project.roles}
                  </p>
                )}
                <ButtonSlug to={slug}>View More</ButtonSlug>
              </div>
            </LeftLayout>
            <RightLayout>
              <GatsbyImage
                image={image}
                alt={project.title}
                className="mx-auto drop-shadow-3xl lg:mx-auto"
              />
            </RightLayout>
          </section>
        )
      })}
    </section>
  )
}
