import React from "react"
import classNames from "classnames"

const LeftLayout = ({ children, height = "h-2/5" }) => {
  const newClassName = classNames(
    "flex w-full bg-tomato items-end md:h-full md:w-2/5 md:items-center md:flex-row-reverse",
    height
  )
  return <div className={newClassName}>{children}</div>
}

export default LeftLayout
