import React from "react"
import Landing from "../components/home/Landing"

import Layout from "../components/Layout"
import Seo from "../components/SEO"

const Home = () => {
  return (
    <>
      <Seo title="Home" />
      <section className="scroll-smooth">
        <Layout>
          <Landing />
        </Layout>
      </section>
    </>
  )
}

export default Home
