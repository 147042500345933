import React from "react"
import classNames from "classnames"

const RightLayout = ({ children, height = "h-3/5" }) => {
  const newClassName = classNames(
    "flex w-full md:h-full md:w-3/5 md:items-center",
    height
  )
  return <div className={newClassName}>{children}</div>
}

export default RightLayout
